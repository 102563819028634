import React from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { Stack, Typography, Card, CardContent, CardMedia, CardActionArea, useMediaQuery } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo, isEmpty } from 'lodash';
import { COLLECTION_PAGE } from '../../utils/pageTypes';
import missingImageDefault from '../../static/placeholder/collection_image.jpg';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import { trimString } from '../../utils/stringUtils';
import { getTextAlignPosition } from '../../utils/getPositions';

export const AspectRatio = AspectRatioPrimitive;

const CollectionRoot = styled(Stack)(() => ({
  position: 'relative',
  cursor: 'pointer'
}));

const CardStyle = styled(Card)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderRadius: 0
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

export const CollectionCardListItem = ({ isWideView = false, config, collection, handleNavigationClick, sx }) => {
  const { name, resource, image, count } = collection;
  const { prominentTitle, textPosition, showNumberOfProducts } = config;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const textPositionAlignment = getTextAlignPosition(textPosition);

  return (
    <CollectionRoot {...sx}>
      <MotionInView variants={varFadeIn}>
        <CardStyle
          onClick={() => {
            if (resource) {
              handleNavigationClick(COLLECTION_PAGE, resource);
            }
          }}
          sx={{
            ...(isWideView && { marginBottom: (theme) => theme.spacing(3) })
          }}
        >
          <StyledCardActionArea>
            <AspectRatio.Root ratio={1 / 1}>
              <StyledCardMedia
                component={MLazyLoadImage}
                source={image || missingImageDefault}
                alt={defaultTo(name, '')}
                imgSx={{
                  height: '100%',
                  width: '100%'
                }}
                sx={{ height: '100%', width: '100%', borderRadius: (theme) => theme.shape.borderRadiusSm }}
              />
            </AspectRatio.Root>
          </StyledCardActionArea>

          <CardContent
            component={Stack}
            justifyContent="center"
            spacing={0.5}
            sx={{
              textAlign: textPositionAlignment,
              p: (theme) => theme.spacing(2),
              ...(Boolean(isMobile && isWideView) && { px: (theme) => theme.spacing(1) }),
              ...(Boolean(isMobile && !isWideView) && { px: 0 })
            }}
          >
            {!isEmpty(name) && (
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                {...(prominentTitle && { sx: { fontWeight: (theme) => theme.typography.fontWeightBold } })}
              >
                {trimString(50, name)}
              </Typography>
            )}
            {Boolean(showNumberOfProducts && count) && (
              <Typography variant={isMobile ? 'body2' : 'body1'} color="text.secondary">
                {`${count} Products`}
              </Typography>
            )}
          </CardContent>
        </CardStyle>
      </MotionInView>
    </CollectionRoot>
  );
};
