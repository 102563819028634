import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import { defaultTo, orderBy } from 'lodash';
import Page from './components/core/Page';
import { HomePageComponents } from './components/home/HomePageComponents';
import TemplateLayout from './layout/TemplateLayout';
import ThemeConfig from './theme';
import { Loader } from './components/core/Loader';
import { SYSTEM_PAGE } from './utils/pageTypes';

CustomPageTemplate.propTypes = {
  /** pagePath:
   * Current location path id e.g. /terms-and-conditions, /:product-name
   * For the home page this is static otherwise this will be a variable.
   * When set within the editor the value will be pre-defined as you navigate the editor.
   * When consumed by hosting app then this will be pulled from the url path. */
  context: PropTypes.object,
  config: PropTypes.object.isRequired,
  logo: PropTypes.object,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleGetProduct: PropTypes.func.isRequired,
  handleGetCollectionProducts: PropTypes.func.isRequired,
  handleGetCollections: PropTypes.func.isRequired,
  handleIsAddedToCart: PropTypes.func.isRequired,
  handleBuyNow: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  cartTotal: PropTypes.number.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function CustomPageTemplate({
  context,
  logo,
  config,
  page,
  cartTotal,
  currency: currencyObj,
  currencies,
  currenciesLoading,
  loading,
  handleBuyNow,
  handleAddToCart,
  handleRemoveFromCart,
  handleGetProduct,
  handleGetCollectionProducts,
  handleGetCollections,
  handleIsAddedToCart,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter
}) {
  const { pagePath } = context;
  const pageTitle = page?.title;
  const pageDescription = page?.description;
  const navigation = { type: SYSTEM_PAGE, path: pagePath };
  const theme = defaultTo(config?.global?.theme, {});
  const settings = defaultTo(config?.settings, {});
  const custom = defaultTo(config?.customs[pagePath], {});
  const components = defaultTo(custom?.components, []);
  const ordered = orderBy(components, 'order', 'asc');
  const currency = currencyObj?.symbol;

  return (
    <ThemeConfig customTheme={theme} settings={settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currencyObj}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: custom?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          {loading ? (
            <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ position: 'relative', width: '100%', height: '500px' }}>
                {loading && <Loader sx={{ position: 'absolute' }} />}
              </Box>
            </Container>
          ) : (
            <HomePageComponents
              config={config}
              components={ordered}
              currency={currency}
              handleBuyNow={handleBuyNow}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleIsAddedToCart={handleIsAddedToCart}
              handleGetProduct={handleGetProduct}
              handleGetCollectionProducts={handleGetCollectionProducts}
              handleGetCollections={handleGetCollections}
              handleNavigationClick={handleNavigationClick}
            />
          )}
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}
