import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { isEmpty, orderBy } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import useMobile from '../../hooks/useMobile';
import { CarouselControlsArrowsIndex } from '../core/carousel';
import ImageAndTextCommon from './common/ImageAndTextCommon';

export const AspectRatio = AspectRatioPrimitive;

const RootStyle = styled(Box)(() => ({
  position: 'relative'
}));

function CarouselItem({ item, handleNavigationClick }) {
  const isMobile = useMobile();
  return (
    <ImageAndTextCommon
      isMobile={isMobile}
      verticalAnimation={false}
      component={item}
      handleNavigationClick={handleNavigationClick}
    />
  );
}

function CarouselImageAndText({ component, handleNavigationClick = () => {} }) {
  const { items, speedInSeconds, showDots, showArrows, isDraggable, autorotate, rtl } = component;
  const ordered = orderBy(items, 'order', 'asc');
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(rtl ? items.length - 1 : 0);

  const settings = {
    // DEFAULTS
    adaptiveHeight: true,
    speed: 800,
    pauseOnHover: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // CONFIGURABLE
    autoplay: autorotate,
    autoplaySpeed: speedInSeconds * 1000,
    swipe: isDraggable,
    dots: showDots,
    arrows: showArrows,
    rtl,
    beforeChange: (_, next) => setCurrentIndex(next)
  };

  useEffect(() => {
    // Fix for editor not moving from previous state to new state
    if (autorotate) {
      carouselRef.current.slickPlay();
    }
  }, [autorotate]);

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <RootStyle>
      {!isEmpty(ordered) && (
        <>
          <Slider ref={carouselRef} {...settings}>
            {ordered.map((item) => (
              <CarouselItem
                key={item.id}
                item={item}
                // isActive={index === currentIndex}
                handleNavigationClick={handleNavigationClick}
              />
            ))}
          </Slider>

          {showArrows && (
            <CarouselControlsArrowsIndex
              index={currentIndex}
              total={ordered.length}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </>
      )}
    </RootStyle>
  );
}

export default CarouselImageAndText;
