import React, { useCallback, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, Box, Grid, Container } from '@material-ui/core';
import { compact, defaultTo, isEmpty, min, orderBy } from 'lodash';
import { getDesktopItemsPerRow } from '../../utils/getItemsPerRow';
import useMobile from '../../hooks/useMobile';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  GALLERY_IMAGES_PLACEHOLDER_HEIGHT,
  DEFAULT_GALLERY_IMAGES_MISSING_DESCRIPTION,
  DEFAULT_GALLERY_IMAGES_MISSING_TITLE,
  GALLERY_IMAGES_VIEW_TYPE,
  DEFAULT_DEKTOP_GALLERY_IMAGE_GRID_SIZE,
  DEFAULT_MOBILE_GALLERY_IMAGE_GRID_SIZE,
  homeDefaultContainerSpacing,
  VERTICAL_VIEW_TYPE
} from '../../utils/constants';
import { ComponentHeader } from './ComponentHeader';
import EmptyContent from '../core/EmptyContent';
import { GalleryImageItem } from '../gallery/GalleryImageItem';
import LightboxModal from '../core/product/LightboxModal';

const INITIAL_STATE = {
  openLightbox: false,
  selectedImage: 0
};

const RootStyle = styled(Box)(() => ({
  position: 'relative',
  overflowY: 'hidden'
}));

const PlaceholderView = styled(Box)(() => ({
  height: GALLERY_IMAGES_PLACEHOLDER_HEIGHT
}));

function HomeGalleryImages({ component: config }) {
  const [state, setState] = useState(INITIAL_STATE);
  const isMobile = useMobile();
  const {
    title,
    caption,
    titleColour,
    captionColour,
    desktopGalleryRows = 2,
    mobileGalleryRows = 1,
    galleryImagesViewType,
    verticalViewType,
    itemSpacing,
    items: itemsRoot
  } = config;
  const items = defaultTo(itemsRoot, []);
  const numberOfItems = items.length;
  const isWideView = galleryImagesViewType === GALLERY_IMAGES_VIEW_TYPE[1];
  const ordered = orderBy(items, 'order', 'asc');
  const showHeader = !isEmpty(caption) || !isEmpty(title);
  const showVerticalSpacing = verticalViewType === VERTICAL_VIEW_TYPE[0];
  const gridSpacing = defaultTo(itemSpacing, 1);

  const images = compact(items.map((item) => item.desktopImage));

  const getMobileRows = useCallback(
    () => (mobileGalleryRows ? 12 / min([mobileGalleryRows, numberOfItems]) : DEFAULT_MOBILE_GALLERY_IMAGE_GRID_SIZE),
    [mobileGalleryRows, numberOfItems]
  );

  const getDesktopRows = useCallback(
    () =>
      desktopGalleryRows
        ? getDesktopItemsPerRow(desktopGalleryRows, numberOfItems)
        : DEFAULT_DEKTOP_GALLERY_IMAGE_GRID_SIZE,
    [desktopGalleryRows, numberOfItems]
  );

  const handleClick = useCallback(
    (image) => {
      const index = images.findIndex((_image) => _image === image);
      if (index >= 0) {
        setState({
          selectedImage: index,
          openLightbox: true
        });
      }
    },
    [images, setState]
  );

  const gridSizing = {
    xs: isMobile ? getMobileRows() : getDesktopRows()
  };

  return (
    <RootStyle
      sx={{ margin: (theme) => homeDefaultContainerSpacing(isWideView, showHeader, showVerticalSpacing, theme) }}
    >
      <LightboxModal
        images={images}
        photoIndex={state.selectedImage}
        setPhotoIndex={(_index) => setState((prev) => ({ ...prev, selectedImage: _index }))}
        isOpen={state.openLightbox}
        onClose={() => setState({ openLightbox: false })}
      />
      <Container
        maxWidth={false}
        sx={{
          '&.MuiContainer-root': {
            ...(isWideView && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Stack spacing={isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING}>
          {showHeader && (
            <ComponentHeader caption={caption} title={title} titleColour={titleColour} captionColour={captionColour} />
          )}
          {items && !isEmpty(items) ? (
            <Box>
              <Grid container direction="row" spacing={isWideView ? 0 : gridSpacing}>
                {ordered.map((_item) => (
                  <Grid key={_item.id} item {...gridSizing}>
                    <GalleryImageItem isWideView={isWideView} item={_item} handleClick={handleClick} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <PlaceholderView>
              <EmptyContent
                title={DEFAULT_GALLERY_IMAGES_MISSING_TITLE}
                description={DEFAULT_GALLERY_IMAGES_MISSING_DESCRIPTION}
                sx={{ height: '100%' }}
              />
            </PlaceholderView>
          )}
        </Stack>
      </Container>
    </RootStyle>
  );
}

export default HomeGalleryImages;
