export const FEATURED_PRODUCT_PLACEHOLDER_HEIGHT = 730;
export const COLLECTION_LIST_PLACEHOLDER_HEIGHT = 500;
export const COLLECTION_PLACEHOLDER_HEIGHT = 500;
export const DEFAULT_RECENTLY_VIEWED_SIZE = 4;
export const DRAWER_WIDTH = 300;

// These are variables for theme object not PX or REM
export const DESKTOP_CONTAINER_PADDING = 10;
export const MOBILE_CONTAINER_TOP_BOTTOM_PADDING = 6;
export const MOBILE_CONTAINER_LEFT_RIGHT_PADDING = 4.5;
export const MOBILE_STACK_SPACING = 6;
export const DESKTOP_STACK_SPACING = 8;
export const DESKTOP_CONTAINER_TOP_BOTTOM_PADDING = 8;
export const DESKTOP_CONTAINER_LEFT_RIGHT_PADDING = 8;

// CHECKOUT
export const DESKTOP_MAX_CHECKOUT_COMPONENT_WIDTH = 800;

// DEFAULT NUM OF ROWS
export const DEFAULT_MOBILE_GRID_SIZE = 12;
export const DEFAULT_DESKTOP_GRID_SIZE = 3;

// COMPONENTS
export const GALLERY_IMAGES_PLACEHOLDER_HEIGHT = 500;
export const DEFAULT_DEKTOP_GALLERY_IMAGE_GRID_SIZE = 3;
export const DEFAULT_MOBILE_GALLERY_IMAGE_GRID_SIZE = 1;
export const DEFAULT_IMAGE_HEIGHT = 500;

export const homeDefaultContainerSpacing = (isWideView, showHeader, showVerticalSpacing, theme) => {
  if (showHeader) {
    return isWideView ? theme.spacing(DESKTOP_CONTAINER_PADDING, 0) : theme.spacing(DESKTOP_CONTAINER_PADDING, 3);
  }
  if (showVerticalSpacing) {
    return isWideView ? theme.spacing(DESKTOP_CONTAINER_PADDING, 0) : theme.spacing(DESKTOP_CONTAINER_PADDING, 3);
  }
  return isWideView ? theme.spacing(0) : theme.spacing(0, 3);
};

export const MAX_DROPDOWN_WIDTH_LARGE = '1000px';
