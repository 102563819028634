import React from 'react';
import { Box, Button, Typography, Stack } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { defaultTo, isEmpty } from 'lodash';
import { isInvalidText } from '../../utils/nullable';
import { varFadeInRight, varFadeInUp, MotionInView } from './animate';
import { createCustomShadowFromColor } from '../../theme/shadows';

const ButtonStyled = ({ buttonText, buttonColour, buttonTextColour, onButtonClick }) => (
  <Button
    variant="contained"
    onClick={onButtonClick}
    sx={{
      ...(!isInvalidText(buttonTextColour) && { color: buttonTextColour }),
      ...(!isInvalidText(buttonColour) && {
        backgroundColor: buttonColour,
        boxShadow: createCustomShadowFromColor(buttonColour),
        '&.MuiButton-root:hover': {
          backgroundColor: buttonColour
        },

        '&.MuiButton-loading': {
          minWidth: '100px',
          backgroundColor: alpha(buttonColour, 0.24)
        },
        '&.Mui-disabled': {
          minWidth: '100px',
          backgroundColor: alpha(buttonColour, 0.24)
        }
      })
    }}
  >
    {buttonText}
  </Button>
);

const ContentStyled = ({ title, subtitle, caption, body, titleColour, subTitleColour, captionColour, bodyColour }) => {
  return (
    <Stack spacing={1}>
      {!isEmpty(caption) && (
        <Typography
          variant="overline"
          sx={{
            textTransform: 'uppercase',
            color: (theme) => defaultTo(captionColour, theme.palette.text.secondary)
          }}
        >
          {caption}
        </Typography>
      )}
      {!isEmpty(title) && (
        <Typography variant="h3" sx={{ color: (theme) => defaultTo(titleColour, theme.palette.text.primary) }}>
          {title}
        </Typography>
      )}
      {!isEmpty(subtitle) && (
        <Typography variant="h6" sx={{ color: (theme) => defaultTo(subTitleColour, theme.palette.text.primary) }}>
          {subtitle}
        </Typography>
      )}
      {!isEmpty(body) && (
        <Typography variant="body2" sx={{ color: (theme) => defaultTo(bodyColour, theme.palette.text.primary) }}>
          {body}
        </Typography>
      )}
    </Stack>
  );
};
export const CarouselTextBlock = (props) => {
  const { buttonText, position } = props;
  return (
    <Stack spacing={3} sx={{ textAlign: position }}>
      <motion.div variants={varFadeInRight}>
        <ContentStyled {...props} />
      </motion.div>

      {!isEmpty(buttonText) && (
        <motion.div variants={varFadeInRight}>
          <ButtonStyled {...props} />
        </motion.div>
      )}
    </Stack>
  );
};

export const SimpleTextBlock = (props) => {
  const { verticalAnimation: verticalAnimationRoot, buttonText, position } = props;
  const verticalAnimation = defaultTo(verticalAnimationRoot, true);
  return (
    <MotionInView variants={verticalAnimation ? varFadeInUp : varFadeInRight}>
      <Stack spacing={3} sx={{ textAlign: position }}>
        <ContentStyled {...props} />
        {!isEmpty(buttonText) && (
          <Box>
            <ButtonStyled {...props} />
          </Box>
        )}
      </Stack>
    </MotionInView>
  );
};
