import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

LayoutOrientation.propTypes = {
  children: PropTypes.node.isRequired,
  verticalPosition: PropTypes.string.isRequired,
  horizontalPosition: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default function LayoutOrientation({ children, sx, verticalPosition, horizontalPosition, ...other }) {
  return (
    <Box sx={{ display: 'flex', alignItems: verticalPosition, height: '100%' }} {...sx}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: horizontalPosition, width: '100%' }} {...other}>
        {children}
      </Box>
    </Box>
  );
}
