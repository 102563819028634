import { alpha } from '@material-ui/core/styles';

const DEFAULT_DIRECTION = 'to top';
const DEFAULT_SPREAD = '50%';

export const createOverlayWithSpread = (direction, spread, first, second) => {
  if (direction === 'FULL_HEIGHT') {
    return fullHeightSpread(first, second);
  }
  return `linear-gradient(
    ${createDirection(direction)}, 
    ${first} 0%, 
    ${alpha(second, 0)} ${createSpread(spread)}, 
    ${alpha(second, 0)} 100%)`;
};

const fullHeightSpread = (first, second) => {
  return `linear-gradient(
    ${second} 0%, 
    ${alpha(first, 0)} 25%, 
    ${alpha(first, 0)} 75%, 
    ${first} 100%)`;
};

const createSpread = (spread) => {
  switch (spread) {
    case 'LOW':
      return '25%';
    case 'MEDIUM':
      return '50%';
    case 'HIGH':
      return '85%';
    default:
      return DEFAULT_SPREAD;
  }
};

const createDirection = (direction) => {
  switch (direction) {
    case 'TOP_BOTTOM':
      return 'to top';
    case 'BOTTOM_TOP':
      return 'to bottom';
    case 'LEFT_RIGHT':
      return 'to right';
    case 'RIGHT_LEFT':
      return 'to left';
    default:
      return DEFAULT_DIRECTION;
  }
};
