import React, { useMemo } from 'react';
import useMobile from '../../hooks/useMobile';
import ImageAndTextCommon from './common/ImageAndTextCommon';

function SimpleImageAndText({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();

  const SimpleImageAndTextMemo = useMemo(
    () => (
      <ImageAndTextCommon isMobile={isMobile} component={component} handleNavigationClick={handleNavigationClick} />
    ),
    [isMobile, component, handleNavigationClick]
  );

  return SimpleImageAndTextMemo;
}

export default SimpleImageAndText;
