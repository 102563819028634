import React, { useRef } from 'react';
import Slider from 'react-slick';
import { defaultTo, isEmpty, orderBy } from 'lodash';
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Stack, Box, Paper, CardActionArea } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import placeholderGroupedItemImage from '../../static/placeholder/grouped_item_image.jpg';
import { NextArrowButton, PreviousArrowButton } from '../core/carousel/controls/CarouselControlsArrowsIndex';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px',
  maxHeight: '850px'
};

const RootStyle = styled(Box)(() => ({
  position: 'relative',
  overflowY: 'hidden'
}));

const CarouselOverlay = styled(Box)({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
});

const CarouselImgStyle = styled(MLazyLoadImage)({
  top: 0,
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
});

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.buttonBorderRadius,
  backgroundColor: theme.palette.secondary.main
}));

function CarouselItem({ index, item, handleNavigationClick }) {
  const theme = useTheme();

  const { showOverlay, desktopImage, navigation } = item;

  return (
    <Paper
      sx={{
        position: 'relative',
        ...HEIGHT_CONSTRAINT_STYLE
      }}
    >
      <StyledCardActionArea
        onClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
        sx={{ ...HEIGHT_CONSTRAINT_STYLE }}
      >
        <AspectRatio.Root ratio={3 / 4}>
          <CarouselImgStyle
            alt={`Carousel image item ${index + 1}`}
            src={desktopImage || placeholderGroupedItemImage}
            imgSx={{ borderRadius: 0 }}
          />
        </AspectRatio.Root>
        <CarouselOverlay
          sx={{
            ...(showOverlay && {
              backgroundImage: `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(
                theme.palette.grey[900],
                0
              )} 100%)`
            })
          }}
        />
      </StyledCardActionArea>
    </Paper>
  );
}

function HomeGroupedImages({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();
  const { items, isDraggable, showArrowsMobile, buttonPrimaryColour, buttonSecondaryColour } = component;
  const ordered = orderBy(items, 'order', 'asc');
  const carouselRef = useRef();
  const buttonStyle = {
    color: (theme) => defaultTo(buttonSecondaryColour, theme.palette.secondary.contrastText),
    opacity: 1
  };
  const buttonWrapperStyle = {
    ...(buttonPrimaryColour && { backgroundColor: buttonPrimaryColour })
  };
  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  const settings = {
    speed: 0,
    swipe: isDraggable,
    dots: false,
    arrows: false,
    autoplay: false,
    slidesToShow: isMobile ? 1 : ordered.length,
    slidesToScroll: 1
  };

  return (
    <RootStyle
      sx={{
        ...HEIGHT_CONSTRAINT_STYLE
      }}
    >
      {!isEmpty(ordered) && (
        <>
          <Slider ref={carouselRef} {...settings}>
            {ordered.map((item, index) => (
              <CarouselItem index={index} key={item.id} item={item} handleNavigationClick={handleNavigationClick} />
            ))}
          </Slider>

          {Boolean(isMobile && showArrowsMobile && ordered.length > 1) && (
            <Stack direction="row" justifyContent="space-evenly" sx={{ margin: (theme) => theme.spacing(2) }}>
              <ButtonWrapper sx={{ ...buttonWrapperStyle }}>
                <PreviousArrowButton sx={{ ...buttonStyle }} onPrevious={handlePrevious} />
              </ButtonWrapper>
              <ButtonWrapper sx={{ ...buttonWrapperStyle }}>
                <NextArrowButton sx={{ ...buttonStyle }} onNext={handleNext} />
              </ButtonWrapper>
            </Stack>
          )}
        </>
      )}
    </RootStyle>
  );
}

export default HomeGroupedImages;
