import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { defaultTo, isEmpty } from 'lodash';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import useMobile from '../../hooks/useMobile';
import { FEATURED_PRODUCT_PLACEHOLDER_HEIGHT } from '../../utils/constants';
import ProductSumary from '../core/product/ProductSumary';
import ProductHeroCarousel from '../core/product/ProductHeroCarousel';
import { varFadeInRight, MotionInView, varFadeInLeft } from '../core/animate';
import ProductFooter from './ProductFooter';
import { gridSizing } from '../../utils/grid';

const PlaceholderView = styled(Box)(() => ({
  height: FEATURED_PRODUCT_PLACEHOLDER_HEIGHT
}));

export const ProductView = ({
  isWidget,
  isWideView,
  config,
  currency,
  product,
  messagingPlacements,
  handleProductChange,
  handleIsAddedToCart,
  handleAddToCart,
  handleRemoveFromCart,
  handleBuyNow,
  handleNavigationClick,
  sx
}) => {
  const isMobile = useMobile();
  const { switchDesktopOrientation, showSpacingTop } = config;
  const showSpacingTopValue = isMobile ? 0 : 5;

  return product ? (
    <Box {...sx}>
      <Grid
        container
        sx={{ ...(showSpacingTop && { mt: (theme) => theme.spacing(showSpacingTopValue) }) }}
        direction={switchDesktopOrientation ? 'row-reverse' : 'row'}
      >
        <Grid item {...gridSizing(isMobile)}>
          <MotionInView variants={varFadeInLeft}>
            <ProductHeroCarousel
              config={config}
              name={product?.name}
              images={isEmpty(defaultTo(product.images, [])) ? [missingImageDefault] : product.images}
            />
          </MotionInView>
        </Grid>
        <Grid item {...gridSizing(isMobile)}>
          <MotionInView variants={varFadeInRight}>
            <ProductSumary
              isWidget={isWidget}
              isWideView={isWideView}
              currency={currency}
              product={product}
              messagingPlacements={messagingPlacements}
              handleProductChange={handleProductChange}
              handleIsAddedToCart={handleIsAddedToCart}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleBuyNow={handleBuyNow}
              handleNavigationClick={handleNavigationClick}
              config={config}
            />
          </MotionInView>
        </Grid>
        {!isWidget && (
          <Grid item xs={12}>
            <MotionInView variants={varFadeInLeft}>
              <ProductFooter
                product={product}
                isWideView={isWideView}
                handleNavigationClick={handleNavigationClick}
                config={config}
              />
            </MotionInView>
          </Grid>
        )}
      </Grid>
    </Box>
  ) : (
    <PlaceholderView />
  );
};
