import React from 'react';
import { Divider, Stack } from '@material-ui/core';
import {
  SimpleImageAndText,
  HomeCollectionProducts,
  HomeCollectionList,
  HomeFeaturedProduct,
  CarouselImageAndText,
  HomeGroupedImages,
  VerticalImageAndText,
  SimpleBodyText
} from '.';
import HomeGalleryImages from './HomeGalleryImages';

export const HomePageComponents = ({
  config,
  components,
  handleIsAddedToCart,
  handleGetProduct,
  handleGetCollectionProducts,
  handleGetCollections,
  ...other
}) => {
  const { showDivider } = config?.layout?.body;
  return (
    <Stack
      direction="column"
      {...(showDivider && { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> })}
    >
      {components.map((component) => {
        switch (component?.key) {
          case 'TEXT':
            return <SimpleBodyText key={component.id} component={component} {...other} />;
          case 'IMAGE_TEXT':
            return <SimpleImageAndText key={component.id} component={component} {...other} />;
          case 'VERTICAL_IMAGE_TEXT':
            return <VerticalImageAndText key={component.id} component={component} {...other} />;
          case 'CAROUSEL':
            return <CarouselImageAndText key={component.id} component={component} {...other} />;
          case 'GROUPED_IMAGES':
            return <HomeGroupedImages key={component.id} component={component} {...other} />;
          case 'GALLERY_IMAGES':
            return <HomeGalleryImages key={component.id} component={component} {...other} />;
          case 'COLLECTION':
            return (
              <HomeCollectionProducts
                key={component.id}
                config={config}
                component={component}
                handleGetCollectionProducts={handleGetCollectionProducts}
                {...other}
              />
            );
          case 'COLLECTION_LIST':
            return (
              <HomeCollectionList
                key={component.id}
                component={component}
                handleGetCollections={handleGetCollections}
                {...other}
              />
            );
          case 'FEATURED_PRODUCT':
            return (
              <HomeFeaturedProduct
                key={component.id}
                config={config}
                component={component}
                handleGetProduct={handleGetProduct}
                handleIsAddedToCart={handleIsAddedToCart}
                {...other}
              />
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
};
